<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">

        <div class="row">
            <div class="col-12">
                <validated-text-area label="Text*" v-model="model.text" name="Text"
                                    :rules="{required: true, max:110}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>

    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'EditOurFeatures',

    props : { initialData : { type : Object } },

    data () {
        return {
            addUrl : urls.cms.ourFeatures.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Edited Our Features..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
