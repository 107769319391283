<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>OUR FEATURES</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.ourFeaturesAddModal.show()" text="Add"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10" ref="table">

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>

        <modal title="Add Our Features" ref="ourFeaturesAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddOurFeatures @success="formSuccess"></AddOurFeatures>
        </modal>

        <modal title="Edit Our Features" ref="ourFeaturesEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditOurFeatures :initial-data="editingItem" @success="formSuccess"></EditOurFeatures>
        </modal>

        <delete-modal ref="ourFeaturesDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Our Features <b v-html="deletingItem && deletingItem.title"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.title"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import axios from 'secure-axios';
import AddOurFeatures from './AddOurFeatures';
import EditOurFeatures from './EditOurFeatures';

export default {
    name : 'OurFeatures',

    components : { AddOurFeatures, EditOurFeatures },

    data () {
        return {
            listUrl   : urls.cms.ourFeatures.list,
            deleteUrl : urls.cms.ourFeatures.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'text',
                    sortField : 'text',
                    title     : 'Text'
                },
                {
                    name      : '__slot:is_enabled',
                    sortField : 'is_enabled',
                    title     : 'Enabled',
                    dataClass : 'center aligned'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {

        formSuccess () {
            const refs = this.$refs;
            refs.ourFeaturesAddModal.close();
            refs.ourFeaturesEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.ourFeaturesEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.ourFeaturesDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.ourFeaturesDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        setEnable (rowData) {
            const that = this;
            rowData.loading = true;
            axios.form(urls.cms.ourFeatures.addEdit, rowData).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    rowData.is_enabled = !rowData.is_enabled;
                    rowData.loading = !rowData.loading;
                    that.$notify('Successfully updated', 'Success', {
                        type : 'success'
                    });
                    that.$refs.table.refreshTable();
                } else {
                    rowData.is_enabled = !rowData.is_enabled;
                    rowData.loading = !rowData.loading;
                }
            }).catch(function (exceptions) {
                console.log('exceptions : ', exceptions);
                rowData.is_enabled = !rowData.is_enabled;
                rowData.loading = !rowData.loading;
                that.$notify('Server error', 'Error', {
                    type : 'error'
                });
            });
        }

    }
};
</script>

<style scoped>

</style>
