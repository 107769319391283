import { render, staticRenderFns } from "./OurFeatures.vue?vue&type=template&id=1d62f316&scoped=true&"
import script from "./OurFeatures.vue?vue&type=script&lang=js&"
export * from "./OurFeatures.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d62f316",
  null
  
)

export default component.exports